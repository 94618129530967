import PrivatePolicy from "./utils/PrivatePolicy";

class Site {
	public static PrivatePolicy = PrivatePolicy;

	public static discover($parent?: JQuery): void {
		PrivatePolicy.discover($parent);
	}

	public static init(): void {
		(<any> window).Site = Site;

		PrivatePolicy.init();

		$(() => {
			this.discover();
		});
	}
}

Site.init();
